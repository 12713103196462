import { useState } from 'react';
import { ModuleData } from '@commons/home';
import { ProductCarouselWithTiles } from '@components/ProductCarouselWithTiles/ProductCarouselWithTiles';
import { useLocalization } from '@hooks/useLocalization';
import { CAROUSEL_TYPES } from '@commons/carousel';

interface ProductCarouselWithTilesModuleProps {
  module: ModuleData;
  position?: number;
  className?: string;
  classNameForLink?: string;
  hasLeftNav?: boolean;
}

export const ProductCarouselWithTilesModule = ({
  module,
  position,
  className,
  classNameForLink,
  hasLeftNav,
}: ProductCarouselWithTilesModuleProps) => {
  const { t } = useLocalization();
  const [shouldShowModule, setShouldShowModule] = useState(true);

  const handleProductsTrimmed = (productsLength: number) => {
    setShouldShowModule(!!productsLength);
  };

  if (!shouldShowModule) {
    return null;
  }

  return (
    <div data-position={position} className={className}>
      <ProductCarouselWithTiles
        products={module.featuredProducts}
        productCarouselType={CAROUSEL_TYPES.MODULE_DEFAULT}
        title={module.basicModuleData.headline}
        linkToAll={module.basicModuleData.linkPath}
        linkText={t('carousel.seeAll')}
        onProductsTrimmed={handleProductsTrimmed}
        termsLinkText={module.basicModuleData.termsText}
        termsModalContent={module.basicModuleData.termsEditorialContent}
        classNameForLink={classNameForLink}
        hasLeftNav={hasLeftNav}
      />
    </div>
  );
};
