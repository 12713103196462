import { ProductTile } from '@commons/product';
import { MAX_SLIDES, MIN_SLIDES } from './ReorderCarousel.constants';

export const getAvailableProducts = (products: ProductTile[]): ProductTile[] =>
  products.filter((product) => product.available);

export const getSlidesCount = (products: ProductTile[], slideSize: number): number => {
  const isNotFitInSlide = products.length > slideSize;

  return isNotFitInSlide ? MAX_SLIDES : MIN_SLIDES;
};
