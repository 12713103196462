import { useState } from 'react';
import cx from 'classnames';
import { IconButton, Typography, Grid } from '@mui/material';
import Image from 'next/image';
import { YoutubeEmbed } from '@components/YoutubeEmbded/YoutubeEmbed';
import { Link } from '@components/UI/Link/Link';
import { play as PlayIcon } from '@assets/icons/system';
import type { ModuleData } from '@commons/home';
import { hasModuleImageHighPriority } from '@helpers/common.helpers';
import { TermsModule } from '../TermsModule/TermsModule';
import styles from './EducationaModule.module.scss';

interface EducationalModuleProps {
  module: ModuleData;
  position?: number;
  className?: string;
  classNameForLink?: string;
}

export const EducationalModule = ({
  module,
  position,
  className,
  classNameForLink,
}: EducationalModuleProps) => {
  const [isPlayClicked, setIsPlayClicked] = useState(false);

  const html = module.basicModuleData.subhead;

  const handleImageClick = () => {
    setIsPlayClicked(true);
  };

  return (
    <div className={cx(styles.wrapper, className)} data-position={position}>
      <Grid direction={['column-reverse', 'row']} container>
        <Grid item xs>
          <div className={styles.video_container}>
            {!isPlayClicked ? (
              <>
                <IconButton className={styles.play_btn} onClick={handleImageClick}>
                  <PlayIcon />
                </IconButton>
                <Image
                  data-testid="educational-module-img"
                  sizes="(max-width: 768px) 100vw, 1000px"
                  src={module.basicModuleData.image}
                  alt=""
                  fill
                  priority={hasModuleImageHighPriority(position)}
                />
              </>
            ) : (
              <YoutubeEmbed embedId={module.youtubeVideoId} title={module.basicModuleData.type} />
            )}
          </div>
        </Grid>
        <Grid item xs>
          <Typography className={styles.title} variant="h3">
            {module.basicModuleData.headline}
          </Typography>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
          <Link
            legacyBehavior
            className={cx(styles.link, classNameForLink)}
            href={module.basicModuleData.linkPath}
          >
            {module.basicModuleData.linkText}
          </Link>
          <TermsModule
            linkText={module.basicModuleData.termsText}
            modalContent={module.basicModuleData.termsEditorialContent}
            className={styles.terms_link}
          />
        </Grid>
      </Grid>
    </div>
  );
};
