/* eslint-disable @typescript-eslint/naming-convention */
import { ProductTile } from './product';

export const MODULE_TYPES = {
  HERO_DISPLAY: 'HeroDisplay',
  BANNER_PAIR: 'BannerPair',
  PRODUCT_TILE_CAROUSEL: 'ProductTileCarousel',
  LARGE_PRODUCT_TILE_CAROUSEL: 'LargeProductTile',
  FEATURED_CATEGORY_PRODUCTS: 'FeaturedCategoryProducts',
  VIDEO_SECTION: 'VideoSection',
  REORDER_PRODUCT_TILE_CAROUSEL: 'ReorderProductTileCarousel',
};

export interface BasicModuleData {
  type: string;
  headline: string;
  subhead: string;
  image: string;
  linkText: string;
  linkPath: string;
  termsText: string;
  termsEditorialContent: string;
}

export interface ModuleData {
  id: string;
  basicModuleData: BasicModuleData;
  keyProduct: ProductTile;
  featuredProducts: ProductTile[];
  modules: BasicModuleData[];
  youtubeVideoId: string;
}
