import { Grid } from '@mui/material';
import { useRouter } from 'next/router';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { CAROUSEL_TYPE, ProductCarousel } from '@components/ProductCarousel/ProductCarousel';
import { getMaxProductsNumberPerSlide } from '@components/ProductCarouselWithTiles/ProductCarouselWithTiles';
import { ModuleData } from '@commons/home';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { getCurrentTitle } from '@utils/getCurrentTitle';
import { ProductListTitleProvider } from '@modules/ga/context/title';
import { hasModuleImageHighPriority } from '@helpers/common.helpers';
import { DYNAMIC_FEATURES, isFeatureEnabled } from '@utils/dynamicFeatures';
import { Heading } from './components/Heading/Heading';
import { LargeTile } from './components/LargeTile/LargeTile';
import styles from './ProductCarouselWithLargeTileModule.module.scss';

enum PRODUCTS_NUMBER_PER_SLIDE {
  MOBILE = 2,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  TABLET = 2,
  DESKTOP = 3,
}

const MAX_SLIDE = 7;
const MIN_SLIDE_TO_SHOW = 4;
const MIN_SLIDE_TO_SHOW_IN_EXPRESS = 1;

function getMaxProductsNumberPerSlideForLargeTile({
  isMobile,
  isTablet,
}: {
  isMobile: boolean;
  isTablet: boolean;
}) {
  if (isMobile) return PRODUCTS_NUMBER_PER_SLIDE.MOBILE;
  if (isTablet) return PRODUCTS_NUMBER_PER_SLIDE.TABLET;
  return PRODUCTS_NUMBER_PER_SLIDE.DESKTOP;
}

interface ProductCarouselWithLargeTileModuleProps {
  module: ModuleData;
  position?: number;
  className?: string;
}

export const ProductCarouselWithLargeTileModule = ({
  module: { featuredProducts: products, keyProduct: product, basicModuleData: description },
  position,
  className,
}: ProductCarouselWithLargeTileModuleProps) => {
  const router = useRouter();
  const { enabled: expressEnabled } = useExpressContext();
  const { isMobile, isTablet, isLargeTablet } = useHeaderContext();
  const maxProductsNumberPerSlideForLargeTile = getMaxProductsNumberPerSlideForLargeTile({
    isMobile: !!isMobile,
    isTablet: !!isTablet || !!isLargeTablet,
  });
  const maxProductsNumberPerSlide = getMaxProductsNumberPerSlide({
    isMobile: !!isMobile,
    isTablet: !!isTablet || !!isLargeTablet,
  });
  const resultProducts = products.slice(0, MAX_SLIDE);
  const shouldNotRender = expressEnabled
    ? products.length < MIN_SLIDE_TO_SHOW_IN_EXPRESS
    : products.length < MIN_SLIDE_TO_SHOW;
  const keyProduct = {
    ...product,
    productImage: { ...product.productImage, largeRef: description.image },
  };
  const isV2 = isFeatureEnabled(DYNAMIC_FEATURES.NEW_PRODUCT_TILES);

  if (shouldNotRender) {
    return null;
  }

  const renderHeading = () => (
    <Heading
      link={{ children: description.linkText, href: description.linkPath || '' }}
      basicModuleData={description}
    />
  );

  if (!product?.productImage?.ref)
    return (
      <div data-position={position}>
        <Grid className={styles.carousel_wrapper} container>
          {renderHeading()}
          <ProductCarousel
            products={resultProducts}
            productsPerView={maxProductsNumberPerSlide}
            className={styles.carousel}
            isV2={isV2}
          />
        </Grid>
      </div>
    );

  return (
    <div data-position={position} className={className}>
      <Grid container className={styles.wrapper}>
        <Grid className={styles.large_tile} item xs={12} sm={6} order={{ xs: 1, sm: 2 }}>
          {isMobile && renderHeading()}
          <ProductListTitleProvider title={getCurrentTitle(router.pathname, description.headline)}>
            <LargeTile
              product={keyProduct}
              imgPriority={hasModuleImageHighPriority(position)}
              isV2={isV2}
            />
          </ProductListTitleProvider>
        </Grid>
        <Grid className={styles.carousel_wrapper} item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
          {!isMobile && renderHeading()}
          <ProductCarousel
            products={resultProducts}
            productsPerView={maxProductsNumberPerSlideForLargeTile}
            className={styles.carousel}
            type={CAROUSEL_TYPE.HALFWIDTH}
            isV2={isV2}
          />
        </Grid>
      </Grid>
    </div>
  );
};
