import { Grid, Skeleton, Typography } from '@mui/material';
import Image from 'next/image';
import { Link } from '@components/UI/Link/Link';
import type { BasicModuleData } from '@commons/home';
import { MODULE_TYPES } from '@commons/home';
import { hasModuleImageHighPriority } from '@helpers/common.helpers';
import { TermsModule } from '../TermsModule/TermsModule';
import styles from './HeroModule.module.scss';

interface HeroModuleProps {
  hero: BasicModuleData;
  reverse?: boolean;
  position?: number;
  showLink?: boolean;
  className?: string;
  loading?: boolean;
  classNameForLink?: string;
}

export const HeroModule = ({
  hero,
  reverse,
  position,
  showLink = true,
  loading,
  className,
  classNameForLink,
}: HeroModuleProps) => {
  const hasImage = !!hero.image && hero.type !== MODULE_TYPES.PRODUCT_TILE_CAROUSEL;

  return (
    <div className={className} data-position={position}>
      <Grid
        direction={reverse ? ['column-reverse', 'row-reverse'] : ['column', 'row']}
        spacing={[10, 2]}
        columns={{ xs: 8, sm: 8, md: 12, lg: 12, xl: 12 }}
        container
      >
        <Grid item xs sm={hasImage ? 3 : 8} md={hasImage ? 4 : 12}>
          <Typography className={styles.header} variant="h2">
            {loading ? <Skeleton /> : hero.headline}
          </Typography>
          <Typography variant="body" className={styles.subheader}>
            {loading ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: hero.subhead }} />
            )}
          </Typography>
          {showLink && hero.linkText && (
            <Link legacyBehavior href={hero.linkPath} className={classNameForLink}>
              <span className={styles.link}>{hero.linkText}</span>
            </Link>
          )}
          <TermsModule linkText={hero.termsText} modalContent={hero.termsEditorialContent} />
        </Grid>
        {hasImage && (
          <>
            <Grid className={styles.separator_grid} item xs={0} sm={1} />
            <Grid item xs sm={4} md={7}>
              <div className={styles.image_container}>
                {loading ? (
                  <Skeleton variant="rectangular" className={styles.skeleton_image} />
                ) : (
                  <Image
                    sizes="(max-width: 768px) 100vw, 1000px"
                    src={hero.image}
                    fill
                    alt=""
                    priority={hasModuleImageHighPriority(position)}
                  />
                )}
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
