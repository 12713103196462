import cx from 'classnames';
import { MODULE_TYPES, ModuleData } from '@commons/home';
import { EducationalModule } from '@components/Modules/EducationalModule/EducationalModule';
import { ProductCarouselWithTilesModule } from '../ProductCarouselWithTilesModule/ProductCarouselWithTilesModule';
import { ProductCarouselWithLargeTileModule } from '../ProductCarouselWithLargeTileModule/ProductCarouselWithLargeTileModule';
import { FeaturedCategoryModule } from '../FeaturedCategoryModule/FeaturedCategoryModule';
import { BannerModule } from '../BannerModule/BannerModule';
import { HeroModule } from '../HeroModule/HeroModule';
import styles from './ModuleFactory.module.scss';

interface ModuleFactoryProps {
  type: typeof MODULE_TYPES[keyof typeof MODULE_TYPES];
  module: ModuleData;
  position: number;
  hasLeftNav?: boolean;
}

export const ModuleFactory = ({ type, module, position, hasLeftNav }: ModuleFactoryProps) => {
  const modules = {
    [MODULE_TYPES.PRODUCT_TILE_CAROUSEL]: (
      <ProductCarouselWithTilesModule
        className={styles.spacer_top}
        module={module}
        position={position}
        classNameForLink={styles.green_text}
        hasLeftNav={hasLeftNav}
      />
    ),
    [MODULE_TYPES.LARGE_PRODUCT_TILE_CAROUSEL]: (
      <ProductCarouselWithLargeTileModule
        className={styles.spacer_top}
        module={module}
        position={position}
      />
    ),
    [MODULE_TYPES.FEATURED_CATEGORY_PRODUCTS]: (
      <FeaturedCategoryModule
        module={module}
        position={position}
        className={styles.spacer_top}
        classNameForLink={styles.green_text}
      />
    ),
    [MODULE_TYPES.VIDEO_SECTION]: (
      <EducationalModule
        className={cx(styles.spacer_top, styles.spacer_bottom)}
        module={module}
        position={position}
        classNameForLink={styles.green_text}
      />
    ),
    [MODULE_TYPES.BANNER_PAIR]: (
      <BannerModule
        className={cx(styles.spacer_top, styles.spacer_bottom)}
        banners={module.modules}
        position={position}
        classNameForLink={styles.green_text}
      />
    ),
    [MODULE_TYPES.HERO_DISPLAY]: (
      <HeroModule
        className={cx(styles.spacer_top, styles.spacer_bottom)}
        hero={module.basicModuleData}
        position={position}
        classNameForLink={styles.green_text}
      />
    ),
  };

  return modules[type] || <></>;
};
