import cx from 'classnames';
import { getYouTubeUrl } from '@utils/getYouTubeUrl';
import styles from './YoutubeEmbed.module.scss';

interface YoutubeEmbedProps {
  embedId: string;
  title: string;
  className?: string;
}

export const YoutubeEmbed = ({ embedId, title, className }: YoutubeEmbedProps) => {
  return (
    <iframe
      className={cx(styles.video, className)}
      title={title}
      src={getYouTubeUrl(embedId)}
      allowFullScreen
    ></iframe>
  );
};
