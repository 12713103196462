import { useMemo } from 'react';
import { ProductTile } from '@commons/product';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';

const enum PRODUCTS_NUMBER_PER_SLIDE {
  MOBILE = 2,
  FROM_MOBILE = 4,
}

const enum PRODUCTS_NUMBER_LIMITS_FOR_MODULE {
  MIN = 4,
  MAX = 8,
}
const MIN_EXPRESS_PRODUCTS_FOR_MODULE = 1;

function getProductTilesWithoutUnavailable(products: ProductTile[]) {
  return products.filter((product) => !product.marketingTags.soldOut);
}

export const useFeaturedCategory = (products: ProductTile[], isMobile: boolean) => {
  const { enabled: expressEnabled } = useExpressContext();
  const availableProducts = getProductTilesWithoutUnavailable(products);
  const productsNumberPerSlide = isMobile
    ? PRODUCTS_NUMBER_PER_SLIDE.MOBILE
    : PRODUCTS_NUMBER_PER_SLIDE.FROM_MOBILE;
  const isCarouselNavigationVisible = availableProducts.length > productsNumberPerSlide;
  const isNotEnoughProductsForModule = expressEnabled
    ? availableProducts.length < MIN_EXPRESS_PRODUCTS_FOR_MODULE
    : availableProducts.length < PRODUCTS_NUMBER_LIMITS_FOR_MODULE.MIN;
  const memoizedProductsPerSlide = useMemo(() => {
    const productsPerSlide = [];
    const maxProducts = availableProducts.slice(0, PRODUCTS_NUMBER_LIMITS_FOR_MODULE.MAX);
    for (let i = 0; i < maxProducts.length; i += productsNumberPerSlide) {
      const group = maxProducts.slice(i, i + productsNumberPerSlide);
      productsPerSlide.push(group);
    }
    return productsPerSlide;
  }, [availableProducts, productsNumberPerSlide]);
  return {
    productsPerSlide: memoizedProductsPerSlide,
    isCarouselNavigationVisible,
    isNotEnoughProductsForModule,
  };
};
