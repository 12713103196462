import { ModuleData, MODULE_TYPES } from '@commons/home';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';

export const useFilterModules = () => {
  const { screenSize } = useHeaderContext();
  const isMobile = !!screenSize?.isMobile;
  const { eligible: expressEnabled } = useExpressContext();

  const MIN_PRODUCTS = 4;
  const MIN_PRODUCTS_FOR_MOBILE = 2;
  const MIN_EXPRESS_PRODUCTS = 1;

  return ({ basicModuleData: { type: moduleType }, featuredProducts }: ModuleData) => {
    const checkProductTileModule = () => {
      const availableProducts = featuredProducts.filter((product) => product.available);
      const minNonExpressItems = isMobile ? MIN_PRODUCTS_FOR_MOBILE : MIN_PRODUCTS;
      const minItems = expressEnabled ? MIN_EXPRESS_PRODUCTS : minNonExpressItems;

      return availableProducts.length >= minItems;
    };
    const genericModuleCheck = () => {
      const isEnoughProductsForModule = expressEnabled
        ? featuredProducts.length >= MIN_EXPRESS_PRODUCTS
        : featuredProducts.length >= MIN_PRODUCTS;

      return isEnoughProductsForModule;
    };

    const moduleConditions = {
      [MODULE_TYPES.PRODUCT_TILE_CAROUSEL]: checkProductTileModule,
      [MODULE_TYPES.LARGE_PRODUCT_TILE_CAROUSEL]: genericModuleCheck,
      [MODULE_TYPES.FEATURED_CATEGORY_PRODUCTS]: genericModuleCheck,
    };
    const condition = moduleConditions[moduleType];

    return !condition || condition();
  };
};
