import { gql } from '@apollo/client';
import { PRODUCT_TILE_FRAGMENT } from '../product/productTileFragment';

export const FEED_MODULE_FRAGMENT = gql`
  fragment feedModuleFragment on FeedModuleData {
    id
    basicModuleData {
      headline
      type
      subhead
      image
      linkText
      linkPath
      termsText
      termsEditorialContent
    }
    keyProduct {
      ...productTileFragment
    }
    featuredProducts {
      ...productTileFragment
    }
    youtubeVideoId
    modules {
      headline
      subhead
      image
      linkText
      linkPath
    }
  }
  ${PRODUCT_TILE_FRAGMENT}
`;
