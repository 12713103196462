import { getMaxProductsNumberPerSlide } from '@components/ProductCarouselWithTiles/ProductCarouselWithTiles';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { ProductTile } from '@commons/product';
import {
  getAvailableProducts,
  getSlidesCount,
} from '@components/ReorderCarousel/ReorderCarousel.helpers';
import { MAX_SLIDES, MIN_ITEMS } from '../ReorderCarousel.constants';

export const useTrimReorderProducts = () => {
  const { isMobile, isTablet, isLargeTablet } = useHeaderContext();

  const slideSize = getMaxProductsNumberPerSlide({
    isMobile: !!isMobile,
    isTablet: !!isTablet || !!isLargeTablet,
  });

  const getTrimmedProducts = (products: ProductTile[]): ProductTile[] => {
    const availableProducts = getAvailableProducts(products);
    const shouldShowLink = slideSize * MAX_SLIDES < availableProducts.length;
    const slidesCount = getSlidesCount(availableProducts, slideSize);
    const maxItems = slideSize * slidesCount - Number(shouldShowLink);

    if (availableProducts.length < MIN_ITEMS) {
      return [];
    }

    if (availableProducts.length > maxItems) {
      return availableProducts.slice(0, maxItems);
    }

    if (availableProducts.length < maxItems) {
      return [...availableProducts, ...new Array(maxItems - availableProducts.length).fill(null)];
    }

    return availableProducts;
  };

  return { getTrimmedProducts };
};
