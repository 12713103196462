import { getProductTile } from '@adapters/product/getProductTile';
import { ProductBasicData, BasicModuleData, FeedModuleData } from '@api';
import { ModuleData } from '@commons/home';

const getBasicModuleData = (module?: BasicModuleData) => {
  return {
    type: module?.type ?? '',
    headline: module?.headline ?? '',
    subhead: module?.subhead ?? '',
    image: module?.image ?? '',
    linkText: module?.linkText ?? '',
    linkPath: module?.linkPath ?? '',
    termsText: module?.termsText ?? '',
    termsEditorialContent: module?.termsEditorialContent ?? '',
  };
};

export const getFeedModule = (module?: DeepPartial<FeedModuleData> | null): ModuleData => {
  return {
    id: module?.id ?? '',
    basicModuleData: getBasicModuleData(module?.basicModuleData),
    keyProduct: getProductTile(module?.keyProduct ?? ({} as ProductBasicData)),
    featuredProducts: module?.featuredProducts?.map(getProductTile) ?? [],
    modules: module?.modules?.map(getBasicModuleData) ?? [],
    youtubeVideoId: module?.youtubeVideoId ?? '',
  };
};
