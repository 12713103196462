import React from 'react';
import Image from 'next/image';
import cx from 'classnames';
import { ProductTile as IProductTile } from '@commons/product';
import { ProductTile } from '@components/Tiles/ProductTile/ProductTile';
import { BaseLink as Link } from '@components/UI';
import { CriteoFormatBeacon } from '@modules/criteo';
import styles from './LargeTile.module.scss';

interface LargeTileProps {
  product: IProductTile;
  className?: string;
  isGridMobileItem?: boolean;
  imgPriority?: boolean;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  isV2?: boolean;
}

export const LargeTile = ({
  product,
  className,
  isGridMobileItem,
  imgPriority = false,
  criteoFormatBeaconView,
  isV2 = false,
}: LargeTileProps) => {
  const wrapperClasses = cx(styles.wrapper, className, { [styles.grid_wrapper]: isGridMobileItem });
  const largeTileClasses = cx(styles.tile, { [styles.grid_tile]: isGridMobileItem });

  return (
    <div className={wrapperClasses}>
      <div className={styles.image_wrapper}>
        <Link href={product.productPageUrl} passHref>
          <a className={styles.link}>
            <Image
              className={styles.image}
              src={product?.productImage.largeRef || ''}
              alt={product?.productImage.alt || ''}
              priority={imgPriority}
              fill
            />
          </a>
        </Link>
        <ProductTile
          className={largeTileClasses}
          isShowImage={false}
          product={product}
          criteoFormatBeaconView={criteoFormatBeaconView}
          isV2={isV2}
        />
      </div>
    </div>
  );
};
