import { Stack } from '@mui/material';
import { getCookie } from 'cookies-next';
import { ModuleData } from '@commons/home';
import { SkeletonWrapper } from '@components/Skeletons/SkeletonWrapper/SkeletonWrapper';
import { ProductTileSkeleton } from '@components/Skeletons/ProductTileSkeleton/ProductTileSkeleton';
import { COOKIES_LIST } from '@commons/cookies';
import { useAuthContext } from '@modules/auth/context';
import { ModuleFactory } from '../ModuleFactory/ModuleFactory';
import { ReorderProductCarouselModule } from '../ReorderProductCarouselModule/ReorderProductCarouselModule';
import styles from './ModuleListCreator.module.scss';
import { useFilterModules } from './useFilterModules';

interface ModuleListCreatorProps {
  data: ModuleData[];
  reorderModule?: ModuleData;
  reorderLoading?: boolean;
  hasLeftNav?: boolean;
}

export const ModuleListCreator = ({
  data,
  reorderModule,
  reorderLoading,
  hasLeftNav,
}: ModuleListCreatorProps) => {
  const startPosition = reorderModule ? 1 : 0;
  const filterModules = useFilterModules();
  const { userInfo } = useAuthContext();

  const shouldShowReorderModule =
    userInfo?.memberSinceYear && getCookie(COOKIES_LIST.IS_SAME_USER) !== undefined;

  return (
    <div className={styles.wrapper}>
      <Stack direction="column" alignItems="stretch">
        {reorderLoading && shouldShowReorderModule && (
          <SkeletonWrapper count={6} skeleton={<ProductTileSkeleton />} />
        )}
        {reorderModule && !reorderLoading && shouldShowReorderModule && (
          <ReorderProductCarouselModule module={reorderModule} position={startPosition} />
        )}
        {data.filter(filterModules).map((module, index) => {
          if (reorderModule?.id !== module.id) {
            const { type } = module.basicModuleData;

            return (
              <ModuleFactory
                key={module.id}
                type={type}
                module={module}
                position={startPosition + index + 1}
                hasLeftNav={hasLeftNav}
              />
            );
          }
        })}
      </Stack>
    </div>
  );
};
