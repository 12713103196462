import { useRouter } from 'next/router';
import { ProductCarouselWithTiles } from '@components/ProductCarouselWithTiles/ProductCarouselWithTiles';
import { ModuleData } from '@commons/home';
import { useLocalization } from '@hooks/useLocalization';
import { getCurrentTitle } from '@utils/getCurrentTitle';
import { useTrimReorderProducts } from '@components/ReorderCarousel/hooks/useTrimReorderProducts';
import { ProductListTitleProvider } from '@modules/ga/context/title';
import { ProductListChannelProvider } from '@modules/ga/context/channel';
import { CAROUSEL_TYPES } from '@commons/carousel';

interface ReorderProductCarouselModuleProps {
  module: ModuleData;
  position?: number;
  isV2?: boolean;
}

export const ReorderProductCarouselModule = ({
  module,
  position,
}: ReorderProductCarouselModuleProps) => {
  const router = useRouter();
  const { t } = useLocalization();
  const { getTrimmedProducts } = useTrimReorderProducts();

  return (
    <ProductListChannelProvider channel={'rec_top_items_qs'}>
      <ProductListTitleProvider
        title={getCurrentTitle(router.pathname, module.basicModuleData.headline)}
      >
        <div data-position={position}>
          <ProductCarouselWithTiles
            products={module.featuredProducts}
            title={module.basicModuleData.headline}
            linkToAll={module.basicModuleData.linkPath}
            linkText={t('carousel.seeAll')}
            termsLinkText={module.basicModuleData.termsText}
            termsModalContent={module.basicModuleData.termsEditorialContent}
            trimProductsFilter={getTrimmedProducts}
            productCarouselType={CAROUSEL_TYPES.MODULE_REORDER}
          />
        </div>
      </ProductListTitleProvider>
    </ProductListChannelProvider>
  );
};
