import { Typography } from '@mui/material';
import React from 'react';
import { Button, ButtonProps } from '@components/UI/Button/Button';
import { useViewAllTracking } from '@hooks/carousel/useViewAllTracking';
import { BasicModuleData } from '@commons/home';
import { TermsModule } from '../../../TermsModule/TermsModule';
import styles from './Heading.module.scss';

export interface HeadingProps {
  link?: ButtonProps;
  basicModuleData: BasicModuleData;
}

export const Heading = ({ basicModuleData, link }: HeadingProps) => {
  const { onClick } = useViewAllTracking(
    `${link?.children ?? ''} ${basicModuleData?.headline ?? ''}`,
  );

  return (
    <div className={styles.wrapper}>
      {basicModuleData?.headline && (
        <Typography variant="h4" className={styles.title}>
          {basicModuleData?.headline}
        </Typography>
      )}
      {basicModuleData?.subhead && (
        <Typography
          component="p"
          variant="body"
          className={styles.subtitle}
          dangerouslySetInnerHTML={{ __html: basicModuleData.subhead }}
        />
      )}
      {link && <Button {...link} variant="underline" onClick={onClick} />}
      <TermsModule
        linkText={basicModuleData.termsText}
        modalContent={basicModuleData.termsEditorialContent}
      />
    </div>
  );
};
