import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import cx from 'classnames';
import { Box } from '@mui/material';
import { ProductTile as ProductTileType } from '@commons/product';
import { ProductTileManager } from '@components/Tiles/ProductTile/ProductTileManager';
import { useLocalization } from '@hooks/useLocalization';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import breakpoints from '@styles/breakpoints.module.scss';
import styles from './FeaturedCategoryCarousel.module.scss';

interface FeaturedCategoryCarouselProps extends SwiperProps {
  slides: ProductTileType[][];
  header?: JSX.Element | boolean;
  className?: string;
  withSeparator?: boolean;
  headerClassName?: string;
  slideClassName?: string;
  isV2?: boolean;
}

export const FeaturedCategoryCarousel = ({
  slides,
  header,
  className,
  withSeparator,
  headerClassName,
  slideClassName,
  onSlideChange,
  onSwiper,
  isV2 = false,
  ...props
}: FeaturedCategoryCarouselProps) => {
  const [activeSlide, setActiveSlide] = useState(1);
  const { t } = useLocalization();
  const totalSlides = slides.length;
  const { screenSize } = useHeaderContext();
  const isMobile = !!screenSize?.isMobile;
  const products: ProductTileType[] = [];

  if (isV2) {
    slides.forEach((slide) => {
      slide.forEach((product) => {
        products.push(product);
      });
    });
  }

  return (
    <Swiper
      className={cx(className, { [styles.v2]: isV2, [styles.featured_carousel]: isV2 })}
      watchSlidesProgress
      preloadImages={false}
      breakpoints={{
        [breakpoints.lg]: {
          allowTouchMove: false,
        },
      }}
      onSlideChange={(slide) => {
        setActiveSlide(slide?.activeIndex + 1);
        onSlideChange?.(slide);
      }}
      onSwiper={onSwiper}
      onSlidesLengthChange={(swiper) => {
        swiper.update();
        onSwiper?.(swiper);
      }}
      role="region"
      aria-label={t('carousel.label')}
      observer={true}
      observeSlideChildren={true}
      observeParents={true}
      wrapperTag={isV2 ? 'ul' : undefined}
      slidesPerView={isV2 ? slides?.[0].length : undefined}
      slidesPerGroup={isV2 ? slides?.[0].length : undefined}
      virtual={isV2 ? false : undefined}
      spaceBetween={isV2 ? 24 : undefined}
      maxBackfaceHiddenSlides={isV2 ? 0 : undefined}
      {...props}
    >
      {header && (
        <div slot="container-start" className={headerClassName}>
          {header}
          {!!totalSlides && (
            <Box aria-live="polite" className="sr_only">
              slide {activeSlide} of {totalSlides}
            </Box>
          )}
        </div>
      )}
      {isV2 &&
        products?.map((product, index) => (
          <SwiperSlide
            tag="li"
            key={product.productId}
            className={cx(
              styles.product_grid_item,
              styles.slide,
              {
                [styles.v2]: isV2,
                [styles.mobile]: isMobile,
              },
              slideClassName,
            )}
          >
            {({ isVisible }) => (
              <ProductTileManager
                product={product}
                className={styles.product}
                nonFocusable={!isVisible}
                isShowImage
                isV2={isV2}
              />
            )}
          </SwiperSlide>
        ))}
      {!isV2 &&
        slides.map((slide, index) => (
          <SwiperSlide
            tag="ul"
            key={index}
            className={cx(
              styles.slide,
              { [styles.separator]: withSeparator, [styles.v2]: isV2 },
              slideClassName,
            )}
          >
            {({ isVisible }) => {
              return slide.map((product) => (
                <li key={product.productId} className={styles.product_grid_item}>
                  <ProductTileManager
                    product={product}
                    className={styles.product}
                    nonFocusable={!isVisible}
                  />
                </li>
              ));
            }}
          </SwiperSlide>
        ))}
    </Swiper>
  );
};
