import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import Image from 'next/image';
import cx from 'classnames';
import { Link } from '@components/UI/Link/Link';
import type { BasicModuleData } from '@commons/home';
import { hasModuleImageHighPriority } from '@helpers/common.helpers';
import { TermsModule } from '../TermsModule/TermsModule';
import styles from './BannerModule.module.scss';

interface BannerModuleProps {
  banners: BasicModuleData[];
  position?: number;
  className?: string;
  classNameForLink?: string;
}

export const BannerModule = ({
  banners,
  position,
  className,
  classNameForLink,
}: BannerModuleProps) => {
  return (
    <div data-position={position} className={className}>
      <Grid direction={['column', 'row']} container>
        {banners.map((banner, index) => (
          <React.Fragment key={banner.headline}>
            <Grid
              className={styles.banner_wrapper}
              columnSpacing={{ xs: 5, lg: 10 }}
              container
              item
              xs
            >
              <Grid className={styles.description} item xs>
                <Typography className={styles.header} variant="h5">
                  {banner.headline}
                </Typography>
                <span
                  className={styles.subheader}
                  dangerouslySetInnerHTML={{ __html: banner.subhead }}
                />
                <Link
                  legacyBehavior
                  href={banner.linkPath}
                  className={cx(styles.link, classNameForLink)}
                >
                  {banner.linkText}
                </Link>
                <TermsModule
                  linkText={banner.termsText}
                  modalContent={banner.termsEditorialContent}
                />
              </Grid>
              {banner.image && (
                <Grid className={styles.image_container} item xs>
                  <Image
                    sizes="400px"
                    src={banner.image}
                    fill
                    alt=""
                    priority={hasModuleImageHighPriority(position)}
                  />
                </Grid>
              )}
            </Grid>
            {index === 0 && <Divider className={styles.banner_divider} />}
          </React.Fragment>
        ))}
      </Grid>
    </div>
  );
};
